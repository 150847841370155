#video_box {
    position:relative;
}
#video_overlays {
    position:absolute;
    width:160px;
    min-height:40px;
    background-color:#dadada;
    z-index:300000;
    bottom:10px;
    left:10px;
    text-align:center;
}


$body-family: 'Product Sans Regular';
@import "../assets/fonts/product_sans/style.css";
@import "../node_modules/bulmaswatch/materia/variables";

$navbar-height:1.0rem;

@import "../node_modules/bulma/bulma.sass";
@import "../node_modules/bulmaswatch/materia/overrides";

$link: black;

$fb-blue:#3c5a9a;
$amz-orange:#ff9900;
$nfx-red:#d81f26;
$ggl-green:#34a853;

.fbblue{
    background: $fb-blue;
}
.amzorange{
    background: $amz-orange;
}
.netflixred{
    background: $nfx-red;
}
.googlegreen{
    background: $ggl-green;
}


.navbar {
    min-height: 1rem;
    
    img.logo{
        position: absolute;
        margin: .4rem;
        height: $navbar-height * 2;
    }
    .navbar-item{
        border-radius: 50%;
        margin-left: 20px;
        padding: 0;
        &:hover{
            background: red;
        }
    }
    .container{
        display: flex;
        justify-content: center;

        .button{
            margin: 10px 20px;
            font-size: .8em;
            text-transform: none;
            box-shadow: none;
        }
    }
  }  

  .hero{
      .title{
          font-size: 4em;
      }
  }

.header{
    margin-top: 3em;
    text-align: center;
}

figure{
    overflow: hidden;
}

section.page{
    height: 100vh;
}
section{
    
    overflow: hidden;
    .container{
        padding-top: $navbar-height * 2;
    }
    &.page1{
    
        background-color: $white;
        height: 100vh;
        
        .hero-body{
            padding: 0;
        }
        .container{
            
            height:100%;
            
            .title{
               
                top: 8vh;
                position: relative;
                width:100%;
                text-align: center;
                img{width:20%;}
            }
            .fango{
                position: relative;
                top: 20vh;
                img{
                    width:50%;
                    margin-left: 25%;
                }
            }
        } 
    }

    &.page2{
        height:100%;
        background: black;
        color: white;
        .phone{
            background-size: cover;
            background-image: url("../assets/video/video.gif");
            img{
                display:block;
            }
        }
        padding-bottom: 6em;
        h1{
            margin: 3em 0;
            color: white;
        }
        p{
            margin-top: 1em;
        }
    }

    &.page3{
        height:70vh;
    }
    
    &.page4{
        height:100%;
        padding-bottom: 6em;
        .quote{
            font-size: 2.5em;
            font-weight: normal;
            padding: 75% 0;
            color:lighten($color: #000000, $amount: 50);
        }
    }
    //curator
    &.page5{
        height:100%;
        padding-bottom: 6em;
        background-color: $fb-blue;
        color:white;

        .text p{
            margin-top:1em;
            text-align: justify;
        }

    }
    //references
    &.page6{
        background-color: $amz-orange;
        color: black;
        //height: 30vh;
        .container{
            margin-bottom: 3em;
            .columns{
                margin-top: 1em;
            }
        }
    }
    //exhibition
    &.page7{
        margin-top: $navbar-height*2;
        background-image: url("../assets/render_hi.jpg");
        background-size: cover;
       
    }
    //exhibition show badge
    &.page8{
        .header{
            margin-top: 0;
        }
        padding-bottom: 3em;;
    }
    ///text
    &.page9{
        //background-image: url("../assets/imgs/fangow.20.jpg");
    }

    .logos{
       
        img{
            height: 128px;
        }
        
    }

}